export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const REGISTER_AUTH = "REGISTER_AUTH";
export const LOGIN_AUTH = "LOGIN_AUTH";
export const LOG_OUT = "LOG_OUT";
export const ERRORS = "ERRORS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_BEGIN = "REGISTER_BEGIN";

export const SHOW_USERS = "SHOW_USERS";
export const SHOW_PRICES = "SHOW_PRICES";
export const SHOW_ADVERTISE = "SHOW_ADVERTISE";
export const SHOW_INFO = "SHOW_INFO";
export const NOT_SHOW_MENU = "NOT_SHOW_MENU";

export const PHOTO_FND = "PHOTO_FND";
export const PHOTO_FOTOPRINT = "PHOTO_FOTOPRINT";
export const PHOTO_PHOTOBOOKS = "PHOTO_PHOTOBOOKS";
export const PHOTO_PHOTOBOOKS_UNIBOOK = "PHOTO_PHOTOBOOKS_UNIBOOK";
export const PHOTO_PHOTOBOOKS_SLIMBOOK = "PHOTO_PHOTOBOOKS_SLIMBOOK";
export const PHOTO_PHOTOBOOKS_BAMBOOK = "PHOTO_PHOTOBOOKS_BAMBOOK";
export const PHOTO_PHOTOBOOKS_PHOTOBOOK = "PHOTO_PHOTOBOOKS_PHOTOBOOK";
export const PHOTO_PHOTOBOOKS_PRINTBOOK = "PHOTO_PHOTOBOOKS_PRINTBOOK";
export const PHOTO_PHOTOBOOKS_PLANSHET = "PHOTO_PHOTOBOOKS_PLANSHET";
export const PHOTO_SCAN = "PHOTO_SCAN";
export const PHOTO_DUPLICATE = "PHOTO_DUPLICATE";
export const PHOTO_RESTORATION = "PHOTO_RESTORATION";
export const PHOTO_BACKGROUND = "PHOTO_BACKGROUND";
export const PHOTO_ADDITION = "PHOTO_ADDITION";
export const PHOTO_BOTTLE = "PHOTO_BOTTLE";
export const PHOTO_PHOTOPICTURE = "PHOTO_PHOTOPICTURE";

export const COMP_XEROX = "COMP_XEROX";
export const COMP_BLACKPRINT = "COMP_BLACKPRINT";
export const COMP_COLORPRINT = "COMP_COLORPRINT";
export const COMP_SCAN = "COMP_SCAN";
export const COMP_LAMINATE = "COMP_LAMINATE";
export const COMP_BINDER = "COMP_BINDER";
export const COMP_USBFLASH = "COMP_USBFLASH";
export const COMP_VHS = "COMP_VHS";
export const COMP_DISCPRINT = "COMP_DISCPRINT";
export const COMP_EMAIL = "COMP_EMAIL";

export const POLIGRAPH_VISITCARD = "POLIGRAPH_VISITCARD";
export const POLIGRAPH_FLYER = "POLIGRAPH_FLYER";
export const POLIGRAPH_CALENDAR = "POLIGRAPH_CALENDAR";
export const POLIGRAPH_TAG = "POLIGRAPH_TAG";
export const POLIGRAPH_STICKER = "POLIGRAPH_STICKER";
export const POLIGRAPH_HANGER = "POLIGRAPH_HANGER";
export const POLIGRAPH_ORACAL = "POLIGRAPH_ORACAL";
export const POLIGRAPH_BANER = "POLIGRAPH_BANER";
export const POLIGRAPH_PVC = "POLIGRAPH_PVC";
export const POLIGRAPH_OTHER = "POLIGRAPH_OTHER";
export const POLIGRAPH_BUKLET = "POLIGRAPH_BUKLET";
export const POLIGRAPH_PLAKAT = "POLIGRAPH_PLAKAT";
export const POLIGRAPH_BROSHURA = "POLIGRAPH_BROSHURA";
export const POLIGRAPH_CALENDAR3D = "POLIGRAPH_CALENDAR3D";
export const POLIGRAPH_BLANK = "POLIGRAPH_BLANK";
export const POLIGRAPH_MENU = "POLIGRAPH_MENU";
export const POLIGRAPH_BLOKNOT = "POLIGRAPH_BLOKNOT";
export const POLIGRAPH_CARDS = "POLIGRAPH_CARDS";
export const POLIGRAPH_LEAF = "POLIGRAPH_LEAF";
export const POLIGRAPH_KONVERT = "POLIGRAPH_KONVERT";
export const POLIGRAPH_FOLDER = "POLIGRAPH_FOLDER";

export const GET_FND = "GET_FND";
export const GET_FOTOPRINT = "GET_FOTOPRINT";
export const GET_PHOTOSCAN = "GET_PHOTOSCAN";
export const GET_DUPLICATE = "GET_DUPLICATE";
export const GET_PHOTOPICTURE = "GET_PHOTOPICTURE";
export const GET_BOTTLE = "GET_BOTTLE";

export const GET_XEROX = "GET_XEROX";
export const GET_BLACKPRINT = "GET_BLACKPRINT";
export const GET_COLORPRINT = "GET_COLORPRINT";
export const GET_SCAN = "GET_SCAN";
export const GET_LAMINATE = "GET_LAMINATE";
export const GET_BINDER = "GET_BINDER";
export const GET_USBFLASH = "GET_USBFLASH";
export const GET_VHS = "GET_VHS";
export const GET_DISCPRINT = "GET_DISCPRINT";
export const GET_EMAIL = "GET_EMAIL";

export const GET_VISITCARD = "GET_VISITCARD";
export const GET_FLYER = "GET_FLYER";
export const GET_CALENDAR = "GET_CALENDAR";
export const GET_BIRKA = "GET_BIRKA";
export const GET_STICKER = "GET_STICKER";
export const GET_HANGER = "GET_HANGER";
export const GET_ORACAL = "GET_ORACAL";
export const GET_BANER = "GET_BANER";
export const GET_PVC = "GET_PVC";